<template>
  <article class="footer-container">
    <div class="footer-content">
      <div class="download-section">
        <div class="download-title">{{ currentLanguageConfig.footer.downloadTitle }}</div>
        <div class="chrome-extension" @click="openTab(currentLanguageConfig.footer.chromeUrl)">{{ currentLanguageConfig.footer.chromeExtension }}</div>
        <div class="edge-extension" @click="openTab(currentLanguageConfig.footer.edgeUrl)">{{ currentLanguageConfig.footer.edgeExtension }}</div>
        <div class="firefox-extension" @click="openTab(currentLanguageConfig.footer.firefoxUrl)">{{ currentLanguageConfig.footer.firefoxExtension }}</div>
        <div class="firefox-extension" @click="openTab(currentLanguageConfig.footer.safariUrl)">{{ currentLanguageConfig.footer.safariExtension }}</div>
        <!-- <div class="safari-extension-coming-soon">
          {{ currentLanguageConfig.footer.safariExtension }}
        </div> -->
      </div>
<!--      <div class="document-section">
        <div class="document-title">{{ currentLanguageConfig.footer.documentTitle }}</div>
        <div class="help-center" @click="openTab(currentLanguageConfig.footer.helpUrl)">{{ currentLanguageConfig.footer.helpCenter }}</div>
        <div class="terms-of-service" @click="openTab(currentLanguageConfig.footer.termsOfServiceUrl)">{{ currentLanguageConfig.footer.termsOfService }}</div>
        <div class="privacy-policy" @click="openTab(currentLanguageConfig.footer.privacyPolicyUrl)">{{ currentLanguageConfig.footer.privacyPolicy }}</div>
      </div>-->
    </div>
<!--  'border-top': isMobileDevice ? '1px solid rgb(224, 224, 224)' : ''  -->
    <div class="footer-copyright"
         :style="{ 'padding-top': isMobileDevice ? '0.5rem' : '' }">
      <div class="footer-copyright-text">
        Copyright © 2018~2024 福流寰宇
        <br v-if="isMobileDevice">
        京ICP备2024064567号-2
      </div>
    </div>
  </article>
</template>

<script>
import {isMobile} from "@/utils/utils";
import {useLanguageStore} from "@/stores/store";
import {computed} from "vue";
import locales from "@/locales";
import {message} from "ant-design-vue";

export default {
  name: "WebFooter",
  computed: {
    isMobileDevice() {
      return isMobile();
    }
  },
  setup() {
    const languageStore = useLanguageStore()
    const currentLanguageConfig = computed(() => {
      return locales[languageStore.currentLang]
    })
    const openTab = (url) => {
      window.open(url);
    };
    const warning = () => {
      message.warning('正在上架，敬请期待...');
    };

    return {
      currentLanguageConfig,
      openTab,
      warning
    }
  }
}
</script>


<style scoped lang="scss">
.footer-container {
  //background: #f8fafc;
  background: var(--footer-background-color);
  width: 100%;
  overflow: hidden;
  position: relative;

  /*  @media only screen and (max-width: 48em) {
      height: 38.7rem;
    }*/
}

.footer-content {
  display: flex;
  flex-direction: row;
  gap: 8em;
  padding-top: 2em;
  padding-left: 10em;

  @media only screen and (max-width: 48em) {
    flex-direction: column;
    gap: 2em;
    padding-left: 2em;
    padding-right: 2em;
  }
}


.download-section {
  display: flex;
  flex-direction: column;
  gap: 1.0625rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 13.5625rem;

  @media only screen and (max-width: 48em) {
    width: 100%;
  }
}

.download-title,
.document-title {
  color: var(--slogan-title-color);
  font-family: "Roboto-Medium", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  //color: #000000;
  text-align: left;
  //font-family: "Inter-Bold", sans-serif;
  //font-size: 1rem;
  //font-weight: 700;
  position: relative;
  align-self: stretch;
}

.chrome-extension,
.edge-extension,
.firefox-extension,
.safari-extension-coming-soon,
.help-center,
.terms-of-service,
.privacy-policy {
  color: var(--slogan-description-color);
  font-family: "Roboto-Regular", sans-serif;
  font-weight: 400;
  //color: #000000;
  text-align: left;
  //font-family: "Inter-Regular", sans-serif;
  font-size: 0.875rem;
  //font-weight: 400;
  position: relative;
  align-self: stretch;
  cursor: pointer;
}

.document-section {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 7rem;

  @media only screen and (max-width: 48em) {
    width: 100%;
  }
}

.footer-copyright {
  padding-right: 6rem;
  color: var(--footer-copyright-color);

  font-family: "Roboto-Medium", sans-serif;
  //color: #9e9e9e;
  text-align: left;
  //font-family: "Inter-Regular", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  //padding-bottom: 1em;

  @media only screen and (max-width: 48em) {
    margin-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    text-align: center;
    width: 100%;
    justify-content: center;
  }
}
</style>